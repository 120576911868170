.places_container {
  .dropdown__indicator, .dropdown__indicator-separator  {
    display: none;
  }
  margin: 1rem 0;
  // max-width: 20rem;
  .loader_container {
    margin: 2rem;
    svg {
      transform: scale(0.5);
    }
  }

  .google_input {
    position: relative;
    input {
      width: 100%;
      padding: 10px;
      border: none;
      margin-bottom: 2px;
      font-size: 14px;
      box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
      margin-bottom: 10px;
    }
    .clear {
      cursor: pointer;
      position: absolute;
      opacity: 0.2;
      top: 10px;
      right: 10px;
      transition: all 0.5s;
      &:hover {
        opacity: 1;
      }
    }
  }


}

.search_container {


  .checkbox_container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 1rem;
    text-align: left;

    input {
      margin-left: 20px;
    }
  }
}

.categories_container {
  margin-bottom: 1rem;

}

.date-picker-container {
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;
  align-items: flex-start;
  @media only screen and (max-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    margin-bottom: 2rem;
    align-items: flex-start;
  }
  input {
    width: 100%;
    padding: 10px;
    border: none;

    font-size: 14px;
    box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);

    margin-top: 20px;

    @media only screen and (max-width: 992px) {
      margin-top: -10px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

}


.search_container {
  margin-top: 2rem;
  .button-form-container {
    text-align: right;
  }
}

.react-datepicker__header {
  text-align: center;
  background-color: $main-color !important;
  border-bottom: 1px solid $main-color !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding-top: 8px;
  position: relative;
  height: 65px;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: $white-color !important;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day-name {
  color: $white-color !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--outside-month {
  color: #ccc !important;
  pointer-events: none;
}

.react-datepicker {

  border: 1px solid $main-color !important;
  border-radius: 0 !important;
  display: inline-block;
  position: relative;
  border-color: $main-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: $main-color !important;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: $white-color !important;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: $white-color !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}
