/*
@File: Pluck Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - Partner CSS
** - About CSS
** - Features CSS
** - UX Research Process CSS
** - CTA CSS
** - Funfacts CSS
** - Feedback CSS
** - Pricing CSS
** - Blog CSS
** - Contact CSS
** - Subscribe CSS
** - Footer CSS
** - Go Top CSS
*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import "site/variables";
@import "site/main";
@import "site/navbar";
@import "responsive";
@import "site/tabs";
@import "site/search";
@import "site/toggle";

