//$font-family: 'Montserrat', sans-serif;
//$main-color: #007bff;
// wcag color
$main-color :#0361c7;
$main-color_opacity: #aad3ff;
//$main-color-opaque: #367BFF;
$main-color-opaque: rgba(#367BFF, 0.3);

$white-color: #ffffff;
$paragraph-color: #646464;
$green-color: #296442;
$black-color: #000000;
$sub-main-color: #28a745;
$disabled: #D3D3D3;
$gradient-color: linear-gradient(to right, #ff5858, #ff536c, #ff517f, #ff5393, #f857a6);
$gradient-color2: linear-gradient(to left bottom, #ff3366, #ed7830, #c2a830, #91ca6e, #66e1bb);
$gradient-color3: linear-gradient(to left bottom, #007bff, #007bff, #449eff, #77b9ff, #bbdcff);
$font-size: 15px;
$transition: .5s;
