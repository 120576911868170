.react-tabs {
  padding: 50px 50px 0 50px;
  min-height: 500px;

  .react-tabs__tab-panel--selected {
    //background-color: $main-color_opacity;
    //border: 2px solid $main-color;
    border-top: none;
  }

  .react-tabs__tab-list {
    border-bottom: 2px solid $main-color;
    margin: 0;
  }

  .react-tabs__tab {
    display: inline-block;
    border: 2px solid transparent;
    bottom: -2px;
    position: relative;
    list-style: none;
    padding: 6px 20px;
    cursor: pointer;
  }

  .react-tabs__tab--selected {
    color: $main-color;
    border-color: $main-color;

    border: 2px solid;
    border-bottom: 2px solid transparent;

    li {
      color: $white-color;
    }
  }


  form {
    padding: 30px 10px;

    .button-form-container {
      margin-top: 50px;
      text-align: right;
      .btn {
        display: inline-block;
        width: auto;
        padding: 16px 34px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

  }


}




.react-tabs__tab-list {
  text-align: left;
}
